@import "bulma/css/bulma.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
    background-color: #232323 !important;
    font-family: "Montserrat", sans-serif;
}

.story {
    max-width: 600px;
    max-width: 600px;
    height: 100%;
    font-family: "Montserrat", sans-serif;
}

.story-container {
    padding-bottom: 10vh;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.see-more {
    font-size: 2rem !important;
    color: white;
    text-align: center;
    width: 150px;
}

.seemore-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 25vh;
}
