@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";

.field{
    margin-top: 20px;
    margin-bottom: 20px;
}

form{
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width:100%;
    height: 100%;
}

.form-logo-container{
    width:100%;
    display:flex;
    justify-content: center;

}

.form-logo{
    width:15%;
    
}

.title{
    font-size: 25px;
    font-weight: bolder;
}
