.form-container-tim {
    z-index: 1000;
    background-color: #0433a3;
    min-width: 20vh;
    max-width: 600px;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
