@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css);
body {
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
}
html {
    height: fill-available;
    height: -webkit-fill-available;
}

body {
    background-color: #232323 !important;
    font-family: "Montserrat", sans-serif;
}

.story {
    max-width: 600px;
    max-width: 600px;
    height: 100%;
    font-family: "Montserrat", sans-serif;
}

.story-container {
    padding-bottom: 10vh;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.see-more {
    font-size: 2rem !important;
    color: white;
    text-align: center;
    width: 150px;
}

.seemore-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 25vh;
}

.form-container-tim {
    z-index: 1000;
    background-color: #0433a3;
    min-width: 20vh;
    max-width: 600px;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.field{
    margin-top: 20px;
    margin-bottom: 20px;
}

form{
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width:100%;
    height: 100%;
}

.form-logo-container{
    width:100%;
    display:flex;
    justify-content: center;

}

.form-logo{
    width:15%;
    
}

.title{
    font-size: 25px;
    font-weight: bolder;
}

.typ {
    background-color: white;
    text-align: center;
    min-height: 100vh;
}

.title {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
}

.form-container-voda {
    z-index: 1000;
    background-color: #e60908;
    max-width: 600px;
    height: 100%;
    padding: 10;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

