.form-container-voda {
    z-index: 1000;
    background-color: #e60908;
    max-width: 600px;
    height: 100%;
    padding: 10;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
