.typ {
    background-color: white;
    text-align: center;
    min-height: 100vh;
}

.title {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
}
